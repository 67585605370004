import { createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from '../api';
import { ServiceProvider } from './serviceProviders';

interface ShieldProvider {
  service_provider: ServiceProvider;
  provider_location: string;
}

type OriginProtocolType = 'HTTP' | 'HTTPS' | 'HTTP & HTTPS';
type OriginType = 'CUSTOM' | 'SERVICE_PROVIDER';

export interface ServiceOrigin {
  id: string;
  service: string;
  host: string;
  port: number;
  protocol: OriginProtocolType;
  is_s3: boolean;
  path: string;
  timeout_ms: number;
  service_provider: ServiceProvider;
  type: OriginType;
  is_private_s3: boolean;
  s3_aws_region: string;
  s3_bucket_name: string;
  shield_location: string;
  shield_providers: ShieldProvider[];
}

interface ServiceOriginsState {
  list: ServiceOrigin[];
  loading: boolean;
  lastFetch: number | null;
  error: string | null;
  pendingOrigin: ServiceOrigin | {};
  updating: boolean;
  updatingShield: boolean;
}

const initialState: ServiceOriginsState = {
  list: [],
  loading: false,
  lastFetch: null,
  error: null,
  pendingOrigin: {},
  updating: false,
  updatingShield: false,
};

const slice = createSlice({
  name: 'serviceOrigins',
  initialState,
  reducers: {
    // actions => action handlers

    serviceOriginsReceived: (serviceOrigins, action) => {
      serviceOrigins.list = action.payload[0];
      serviceOrigins.loading = false;
      serviceOrigins.lastFetch = Date.now();
    },

    serviceOriginUpdated: (serviceOrigins, action) => {
      serviceOrigins.list = serviceOrigins.list.map((content) =>
        content.id === action.payload[0].id ? action.payload[0] : content,
      );
      serviceOrigins.pendingOrigin = {};
      serviceOrigins.updating = false;
      serviceOrigins.updatingShield = false;
    },

    serviceOriginAdded: (serviceOrigins, action) => {
      serviceOrigins.list.push(action.payload[0]);
      serviceOrigins.pendingOrigin = {};
      serviceOrigins.updating = false;
      serviceOrigins.error = null;
    },

    serviceOriginDeleted: (serviceOrigins, action) => {
      const deleted = action.payload[1].payload.id;
      serviceOrigins.list = serviceOrigins.list.filter((p) => p.id !== deleted);
    },

    onError: (serviceOrigins, action) => {
      let msg = action.payload[0];
      const resp = action.payload[1];

      if (resp && resp.data) {
        if (typeof resp.data === 'string' || resp.data instanceof String) {
          msg = resp.data;
        } else {
          msg = resp.data[Object.keys(resp.data)[0]];
        }
      }

      serviceOrigins.error = msg;
    },

    updatePendingOrigin: (serviceOrigins, action) => {
      serviceOrigins.pendingOrigin = action.payload;
      serviceOrigins.updating = Object.keys(action.payload).length > 0;
      serviceOrigins.error = null;
      if (serviceOrigins.pendingOrigin) {
        window.location.hash =
          ('id' in serviceOrigins.pendingOrigin && serviceOrigins.pendingOrigin.id) || '';
      }
    },

    updatePendingOriginShield: (serviceOrigins, action) => {
      serviceOrigins.pendingOrigin = action.payload;
      serviceOrigins.updatingShield = Object.keys(action.payload).length > 0;
      serviceOrigins.error = null;
    },
  },
});

export const {
  serviceOriginsReceived,
  serviceOriginAdded,
  serviceOriginUpdated,
  serviceOriginDeleted,
  onError,
  updatePendingOrigin,
  updatePendingOriginShield,
} = slice.actions;
export default slice.reducer;

// Action Creators

export const loadServiceOrigins = (s: string) =>
  apiCallBegan({
    url: `/api/v1/services/${s}/origins/`,
    onSuccess: serviceOriginsReceived.type,
    onError: onError.type,
  });

export const addServiceOrigin = (s: string, o: ServiceOrigin) =>
  apiCallBegan({
    url: `/api/v1/services/${s}/origins/`,
    method: 'post',
    data: o,
    onSuccess: serviceOriginAdded.type,
    onError: onError.type,
  });

export const deleteServiceOrigin = (s: string, o: string) =>
  apiCallBegan({
    id: o,
    url: `/api/v1/services/${s}/origins/${o}/`,
    method: 'delete',
    onSuccess: serviceOriginDeleted.type,
    onError: onError.type,
  });

export const updateServiceOrigin = (s: string, o: ServiceOrigin) =>
  apiCallBegan({
    id: o.id,
    url: `/api/v1/services/${s}/origins/${o.id}/`,
    method: 'put',
    data: o,
    onSuccess: serviceOriginUpdated.type,
    onError: onError.type,
  });

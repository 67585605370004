import { createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from '../api';

type UnifiedLogType = 'S3' | 'S3_COMPATIBLE';

interface Destination {
  id: string;
  name: string;
  service: string;
  type: UnifiedLogType;
  s3_bucket: string;
  s3_domain: string;
  s3_path: string;
  s3_region: string;
}

interface RealTimeLogsState {
  destinations: Destination[];
  updatingDestination: Destination | null;
  error: string | null;
}

const initialState: RealTimeLogsState = {
  destinations: [],
  updatingDestination: null,
  error: null,
};

const slice = createSlice({
  name: 'realTimeLogs',
  initialState,
  reducers: {
    destinationsReceived: (realTimeLogs, action) => {
      realTimeLogs.destinations = action.payload[0];
    },

    destinationAdded: (realTimeLogs, action) => {
      realTimeLogs.destinations.push(action.payload[0]);
      realTimeLogs.updatingDestination = null;
    },

    destinationDeleted: (realTimeLogs, action) => {
      const deleted = action.payload[1].payload.id;
      realTimeLogs.destinations = realTimeLogs.destinations.filter((p) => p.id !== deleted);
    },

    destinationUpdateStarted: (realTimeLogs, action) => {
      realTimeLogs.updatingDestination = action.payload;
    },

    destinationUpdated: (realTimeLogs, action) => {
      const index = realTimeLogs.destinations.findIndex((c) => c.id === action.payload[0].id);
      realTimeLogs.destinations[index] = action.payload[0];
      realTimeLogs.updatingDestination = null;
    },

    onError: (realTimeLogs, action) => {
      let msg = action.payload[0];
      const resp = action.payload[1];

      if (resp && resp.data) {
        msg = resp.data[Object.keys(resp.data)[0]];
        if (typeof msg != 'string' && !(msg instanceof String)) {
          msg = JSON.stringify(msg[Object.keys(msg)[0]]);
        }
      }

      realTimeLogs.error = msg;
    },
  },
});

export const {
  destinationUpdateStarted,
  destinationsReceived,
  destinationAdded,
  destinationDeleted,
  destinationUpdated,
  onError,
} = slice.actions;

export default slice.reducer;

// Action Creators

export const loadDestinations = (s: string) =>
  apiCallBegan({
    url: `/api/v1/services/${s}/log-destinations/`,
    onSuccess: destinationsReceived.type,
  });

export const addDestination = (s: string, d: Destination) =>
  apiCallBegan({
    url: `/api/v1/services/${s}/log-destinations/`,
    method: 'post',
    data: d,
    onError: onError.type,
    onSuccess: destinationAdded.type,
  });

export const updateDestination = (s: string, r: Destination, id: string) =>
  apiCallBegan({
    id: id,
    url: `/api/v1/services/${s}/log-destinations/${id}/`,
    method: 'put',
    data: r,
    onError: onError.type,
    onSuccess: destinationUpdated.type,
  });

export const deleteDestination = (s: string, id: string) =>
  apiCallBegan({
    id: id,
    url: `/api/v1/services/${s}/log-destinations/${id}/`,
    method: 'delete',
    onError: onError.type,
    onSuccess: destinationDeleted.type,
  });
